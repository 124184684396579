export default {
  references: {
    title: "Spécifications techniques",
    description: "Ci-dessous la spécification technique de ce produit",
  },
  unavailable: "Ce produit n'est pas disponible à l'achat pour le moment.",
  "supplier-listings": {
    form: {
      reserve: "Faire une réservation",
      rfq: "Demander un devis",
      checkout: "Acheter maintenant",
      "sign-up": "Inscrivez-vous ici",
      "sign-in": "Se connecter",
    },
  },
}
